<template>
  <div>
    <a-modal
        :width="800"
        v-model="show"
        title="D类质检图"
        :keyboard="false"
        @cancel="handleCancel"
    >
      <div class="popup-content">
        <div class="content-item" v-for="item in showData" :key="item.shortCode">
          <div class="item-top">
            <div class="item-top-text">标题：<span>{{ item.title }}</span></div>
            <div class="item-top-text">短码：<span class="color-green">{{ item.shortCode }}<span v-if="item.id">{{ '-' + item.id }}</span></span></div>
            <div class="item-top-text" v-if="item.versionName">版别名称：<span class="color-green">{{ item.versionName }}</span></div>
          </div>
          <div class="item-img-list">
            <img
                v-for="(el, i) in item.images "
                :key="i"
                :src="computedNotShowImage(el)"
                alt=""
                @click="handlePreview(item.images)"
            >
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {addStringIfContains} from "@/untils/otherEvent"

export default {
  data() {
    return {
      show: false,
      showData: ''
    };
  },
  computed: {
    computedNotShowImage() {
      return (value) => addStringIfContains(value, 'x-oss', '?x-oss-process=image/resize,p_100/format,jpg')
    },
  },
  methods: {
    async showPopup(data) {
      this.showData = data
      this.show = true
    },
    handleCancel() {
      this.show = false
      this.$emit('closeModal')
    },
    handlePreview(list) {
      const tempList = list.map(el => {
        return {img_url: this.computedNotShowImage(el)}
      })
      this.$previewImg({
        list: tempList,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 100px;
    height: 100px;
  }
}
.item-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item-top-text {
    margin-right: 20px;
    font-size: 15px;
    span {
      font-size: 25px;
      font-weight: bold;
    }
  }
}
.content-item {
  width: 100%;
  margin-bottom: 20px;
}
.popup-content {
  width: 100%;

}
</style>
