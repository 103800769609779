var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 800, title: "D类质检图", keyboard: false },
          on: { cancel: _vm.handleCancel },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticClass: "popup-content" },
            _vm._l(_vm.showData, function(item) {
              return _c(
                "div",
                { key: item.shortCode, staticClass: "content-item" },
                [
                  _c("div", { staticClass: "item-top" }, [
                    _c("div", { staticClass: "item-top-text" }, [
                      _vm._v("标题："),
                      _c("span", [_vm._v(_vm._s(item.title))])
                    ]),
                    _c("div", { staticClass: "item-top-text" }, [
                      _vm._v("短码："),
                      _c("span", { staticClass: "color-green" }, [
                        _vm._v(_vm._s(item.shortCode)),
                        item.id
                          ? _c("span", [_vm._v(_vm._s("-" + item.id))])
                          : _vm._e()
                      ])
                    ]),
                    item.versionName
                      ? _c("div", { staticClass: "item-top-text" }, [
                          _vm._v("版别名称："),
                          _c("span", { staticClass: "color-green" }, [
                            _vm._v(_vm._s(item.versionName))
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "item-img-list" },
                    _vm._l(item.images, function(el, i) {
                      return _c("img", {
                        key: i,
                        attrs: { src: _vm.computedNotShowImage(el), alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.handlePreview(item.images)
                          }
                        }
                      })
                    }),
                    0
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }